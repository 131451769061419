<template>
  <div class="auth-wrapper px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <!-- <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Vuexy
          </h2>
        </b-link> -->
        <h3>Registration Payment</h3>
        <validation-observer ref="socialRules" tag="form">
          <b-card class="instructions-card"
            >Follow The Instructions Below <strong>or</strong> Enter Your Phone
            Number To Get Payment Prompt.</b-card
          >
          <b-row>
            <b-col lg="5" md="6" sm="12">
              <b-card>
                <h5>Payment Instructions</h5>
                <ol>
                  <li>Go to Mpesa Menu on your phone.</li>
                  <li>Select Lipa na Mpesa</li>
                  <li>Select Paybill</li>
                  <li>
                    Enter Paybill No. <strong>884350</strong>, then press Ok
                  </li>
                  <li>
                    Enter Account No.
                    <strong>{{ tracking_number }}</strong
                    >, then press Ok
                  </li>
                  <li>
                    Enter amount Ksh.
                    <strong>{{ new Intl.NumberFormat().format(amount) }}</strong
                    >, then press Ok
                  </li>
                  <li>Enter Your PIN and press Ok</li>
                  <li>Confirm the transaction details</li>
                  <li>Wait for the MPESA transaction confirmation message</li>
                  <li>
                    Press the <strong>Confirm</strong> button below to confirm
                    the transaction
                  </li>
                </ol>
              </b-card>
              <b-card>
                <h5>Use Debit/Credit Card</h5>
                <b-button
                  variant="primary"
                  :disabled="isLoading"
                  @click="sendJambopayRequest"
                  >Complete Transaction Using Debit/Credit Card
                  <b-spinner v-if="isLoading" class="ml-1" small />
                </b-button>
              </b-card>
            </b-col>
            <b-col lg="5" md="6" sm="12">
              <b-card>
                <h5>Request Payment Prompt</h5>
                <validation-observer ref="refEnterPhoneNumberForm">
                  <ul>
                    <li>Enter Your Phone Number to get MPESA Prompt</li>
                  </ul>
                  <b-form-group
                    label="Enter Phone Number"
                    label-for="enterBusinessName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Phone Number"
                      rules="required"
                    >
                      <b-form-input
                        id="enterPhoneNumber"
                        placeholder="Enter your phone number"
                        v-model="userPhoneNumber"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <ul>
                    <li>
                      Click <strong>Confirm</strong> after receciving the MPESA
                      confirmation message.
                    </li>
                  </ul>
                  <b-button
                    class="float-right"
                    variant="primary"
                    @click="sendPaymentPrompt"
                    :disabled="isLoading"
                  >
                    Send Prompt
                    <b-spinner v-if="isLoading" class="ml-1" small />
                  </b-button>
                </validation-observer>
              </b-card>
              <b-card>
                <b-button
                  variant="primary"
                  class="float-right"
                  :disabled="isLoading"
                  @click="confirmPayment"
                  >Confirm Transaction
                  <b-spinner v-if="isLoading" class="ml-1" small />
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormGroup,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    BCard,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userPhoneNumber: '',
      tracking_number: '',

      amount: 0,

      // validation rules
      required,

      isLoading: false,
    }
  },
  mounted() {
    this.member_type = JSON.parse(localStorage.getItem('user')).member_type_id
    this.userPhoneNumber = JSON.parse(
      localStorage.getItem('user')).phone_number
    this.tracking_number = JSON.parse(
      localStorage.getItem('user')).tracking_number

    this.$http
      .get('/membership/payment/info')
      .then(response => {
        this.amount = response.data.balance
        this.tracking_number = response.data.user.tracking_number
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    sendPaymentPrompt() {
      this.isLoading = true
      return new Promise(() => {
        this.$refs.refEnterPhoneNumberForm.validate().then(success => {
          if (success) {
            this.$http
              .post('/membership/registration/stk-push', {
                phone_number: this.userPhoneNumber,
              })
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.data,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.data,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
              .finally(() => {
                this.isLoading = false;
              })
          } else {
            this.isLoading = false;
          }
        })
      })
    },
    sendJambopayRequest() {
      this.isLoading = true
      this.$http.get('membership/jambopay/token').then(response => {
        const checkoutDetails = {
          OrderId: this.tracking_number,
          CustomerEmail: JSON.parse(localStorage.getItem('user')).email,
          Currency: 'KES',
          CustomerPhone: this.userPhoneNumber,
          OrderAmount: response.data.amount,
          BusinessEmail: 'martin@deveint.com',
          CancelledUrl: 'membership/jambopay/failed/callback',
          CallBackUrl: 'membership/jambopay/success/callback',
          Description: 'Checkout description',
          SupportPhone: '0727429744',
          StoreName: 'PRISK',
          ClientKey: response.data.client_key,
        }
        jambopayCheckout(checkoutDetails, (data) => {
          console.log(data)
          if (data.status === 'SUCCESS') {
            this.$http
              .post('membership/jambopay/success/callback', data)
              .then(response => {
                if (response.data.status === 'Fully Paid') {
                  const permissions = JSON.parse(
                    localStorage.getItem('permissions')
                  )
                  this.$router.replace(
                    getHomeRouteForLoggedInUser(permissions[0])
                  )
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Transaction successfull',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                    },
                  })
                } else {
                  this.amount = response.data.data.balance;
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Partial Payment was made. Please complete the remaining amount.',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                    },
                  })
                }
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                this.isLoading = false
              })
          } else if (data.status === 'Failed') {
            this.$http.post('membership/jambopay/failed/callback')
              .then(response => {
                this.tracking_number = response.data.user.tracking_number
              })
              .catch(error => {
                console.log(error)
              })
            this.isLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Transaction failed or was cancelled',
                icon: 'AlertTraingleIcon',
                variant: 'danger',
              },
            });
          } else {
            this.$http.post('membership/jambopay/failed/callback')
              .then(response => {
                this.tracking_number = response.data.user.tracking_number
              })
              .catch(error => {
                console.log(error)
              })
            this.isLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Transaction failed or was cancelled',
                icon: 'AlertTraingleIcon',
                variant: 'danger',
              },
            })
          }
        })
      })
    },
    confirmPayment() {
      this.isLoading = true
      this.$http
        .post('/membership/payment/confirm')
        .then(response => {
          if (response.data.status === 'Fully Paid') {
            const permissions = JSON.parse(localStorage.getItem('permissions'))
            this.$router
              .replace(getHomeRouteForLoggedInUser(permissions[0]))
              // this.$router.replace({name: 'register-content-upload'})
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Transaction Verified Successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                  },
                })
              })
          } else {
            this.amount = response.data.data.balance
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Partial Payment was made. Please complete the remaining amount.',
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.data.message === 'Payment not successful') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Payment unsuccessful.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'An error occured. Please contact admin.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss'
</style>
